@font-face {
  font-family: 'Poppins';
  src: url('./resources/fonts/Poppins-Regular.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adminTitle {
  margin-top: 72px;
  padding-left: 8px;
  font-size: 32px;
  font-weight: 800;
  color: #272c45;
}

.home {
  background: url(https://images.unsplash.com/photo-1554226983-8a81adeda0c1?ixlib=rb-1.2.1&auto=format&fit=crop&w=2400&q=80);
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 3em;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.homeTitle {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 800;
  color: #272c45;
}

.signUpTitle {
  justify-content: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 800;
  color: #272c45;
  margin-top: 16px;
}

.signUpSubtitle {
  justify-content: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #9b9b9b;
}

.homeSubtitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #9b9b9b;
}

.homeButton {
  width: 200px;
  margin-right: 10px;
}

.homeButtons {
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.leftButton {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 16px;
}

.rightButton {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 16px;
}

@media (min-width: 700px) {
  .card {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 3em;
    border-radius: 0.3em;
    background: #ffffff;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }

  .leftButton {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .leftButton Button {
    margin-right: 8px;
  }

  .rightButton {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .rightButton Button {
    margin-left: 8px;
  }

  .signupDeletePet Button {
    width: 90%;
    max-width: 300px;
  }
}

.signUpStepperContainer {
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.MuiStepper-root {
  margin: auto;
  max-width: 650px;
}

.signUpStepTitle {
  display: flex;
  flex:1;
  padding: 24px;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  color: #272c45;
}

.plansContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.plans {
  width: 100%;
  max-width: 512px;
  height: auto;
}

.signupDeletePet {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
}

.signUpPetsPrice {
  font-family: 'Poppins';
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
}

.widthContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.innerWidthContainer {
  display: flex;
  flex: 1;
  max-width: 512px;
}

.signupPaymentAlertTitle {
  font-size: 16px;
  color: #ef4565;
  font-weight: bold;
}

.signupPaymentAlertContent {
  font-size: 16px;
  color: #9b9b9b;
}

.signupPaymentPrice {
  display: flex;
  flex:1;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #272c45;
}

.signupPaymentDiscount {
  font-size: 16px;
  color: #3c763d;
  margin-bottom: 8px;
}

.signupPaymentButtonContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  color: #000;
}

.centeredButtonContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  color: #000;
}

.signupPaymentDisclosure {
  font-size: 16px;
  color: #9a6948;
  margin-bottom: 8px;
}

.signupPaymentNext {
  font-size: 16px;
  color: #9b9b9b;
  margin-bottom: 8px;
}

.signupContainerButton {
  margin-top: 24px;
}

.dropzonePlaceholder {
  border-bottom-width: 1px;
  border-color: '#888';
  border-bottom-style: solid;
  margin-bottom: 0;
  margin-top: 18px;
  padding-Bottom: 8px;
  font-size: 18px;
}

.termsWhatsapp {
  text-decoration: none;
  color: forestgreen;
  cursor: pointer;
}

.termsEmail {
  text-decoration: none;
  color: #d7a27b;
  cursor: pointer;
}

.MuiInputBase-adornedStart > input {
  height: 35px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
